import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './osb-progress-bar.scss';
import { useStepProgressBar } from '../../../../hooks/owners-osb';
import { StepProgressBarDetail } from '../../../../models/osb-model/osb-progress-bar';
import {
    OSB_AEM_PROPERTY_KEYS,
    STEP_PROGRESS_BAR_KEYS,
} from '../../owners-osb/osb-constant';
import {
    OsbPathBookingReviewStep,
    OsbPathCalenderStep,
    OsbPathDealerStep,
    OsbPathDeliveryStep,
    OsbPathPersonalDetailsStep,
    OsbPathServiceLocationStep,
    OsbPathServicesStep,
    OsbPathVehicleStep,
} from '../../owners-osb/osb-controller';
import { getObjectFromAEMJson } from '../../owners-osb/osb-utils';
import serverSideService from '../../../../services/server-side-service/server-side-service';

interface OSBProgressBarProps {
    useOsbV3StepOrder?: boolean;
}

const OSBProgressBar: React.FC<OSBProgressBarProps> = ({
    useOsbV3StepOrder = false,
}) => {
    const {
        osbStepProgressBar,
        setOSBStepProgressBarPayload,
        callContentService,
    } = useStepProgressBar();

    const history = useHistory();
    const [top, setTop] = useState(0);
    const [isMobile, setIsMobile] = useState(
        serverSideService.isClientSide() ? window.innerWidth <= 768 : false
    );

    const getStepNameList = (elements: any[]): string[] => {
        const stepNameList: string[] = [];

        const labels = useOsbV3StepOrder
            ? [
                  OSB_AEM_PROPERTY_KEYS.VEHICLE_STEP_LABEL,
                  OSB_AEM_PROPERTY_KEYS.SERVICE_STEP_LABEL,
                  OSB_AEM_PROPERTY_KEYS.DEALER_STEP_LABEL,
                  OSB_AEM_PROPERTY_KEYS.CONTACT_STEP_LABEL,
                  OSB_AEM_PROPERTY_KEYS.SUMMARY_STEP_LABEL,
              ]
            : [
                  OSB_AEM_PROPERTY_KEYS.DEALER_STEP_LABEL,
                  OSB_AEM_PROPERTY_KEYS.SERVICE_STEP_LABEL,
                  OSB_AEM_PROPERTY_KEYS.DELIVERY_STEP_LABEL,
                  OSB_AEM_PROPERTY_KEYS.LOCATION_STEP_LABEL,
                  OSB_AEM_PROPERTY_KEYS.DATETIME_STEP_LABEL,
                  OSB_AEM_PROPERTY_KEYS.CONTACT_STEP_LABEL,
              ];

        labels.forEach(labelKey => {
            const label = getObjectFromAEMJson(labelKey, elements) || '';
            if (label) {
                stepNameList.push(label);
            }
        });

        const isStepNameExceedFirstBreakpoint = stepNameList.some(
            stepName =>
                stepName.length >
                STEP_PROGRESS_BAR_KEYS.STEP_NAME_LENGTH_BREAKPOINT_1
        );

        if (isStepNameExceedFirstBreakpoint) {
            setOSBStepProgressBarPayload({
                isStepNameExceedFirstBreakpoint: true,
            });
        }

        return stepNameList;
    };

    const updateProgressBarDetails = (stepNameList: string[]) => {
        const stepDetailsMap: {
            [key: string]: { name: string; path: () => string };
        } = useOsbV3StepOrder
            ? {
                  [STEP_PROGRESS_BAR_KEYS.VEHICLE]: {
                      name: stepNameList[0],
                      path: OsbPathVehicleStep,
                  },
                  [STEP_PROGRESS_BAR_KEYS.SERVICE]: {
                      name: stepNameList[1],
                      path: OsbPathServicesStep,
                  },
                  [STEP_PROGRESS_BAR_KEYS.DEALER]: {
                      name: stepNameList[2],
                      path: OsbPathDealerStep,
                  },
                  [STEP_PROGRESS_BAR_KEYS.CONTACT]: {
                      name: stepNameList[3],
                      path: OsbPathPersonalDetailsStep,
                  },
                  [STEP_PROGRESS_BAR_KEYS.SUMMARY]: {
                      name: stepNameList[4],
                      path: OsbPathBookingReviewStep,
                  },
              }
            : {
                  [STEP_PROGRESS_BAR_KEYS.DEALER]: {
                      name: stepNameList[0],
                      path: OsbPathDealerStep,
                  },
                  [STEP_PROGRESS_BAR_KEYS.SERVICE]: {
                      name: stepNameList[1],
                      path: OsbPathServicesStep,
                  },
                  [STEP_PROGRESS_BAR_KEYS.DELIVERY]: {
                      name: stepNameList[2],
                      path: OsbPathDeliveryStep,
                  },
                  [STEP_PROGRESS_BAR_KEYS.LOCATION]: {
                      name: stepNameList[3],
                      path: OsbPathServiceLocationStep,
                  },
                  [STEP_PROGRESS_BAR_KEYS.DATETIME]: {
                      name: stepNameList[4],
                      path: OsbPathCalenderStep,
                  },
                  [STEP_PROGRESS_BAR_KEYS.CONTACT]: {
                      name: stepNameList[5],
                      path: OsbPathPersonalDetailsStep,
                  },
              };

        const updatedProgressBarDetails = osbStepProgressBar.progressBarDetails.map(
            (step: StepProgressBarDetail) => {
                const detail = stepDetailsMap[step.key];
                if (detail) {
                    return {
                        ...step,
                        stepName: detail.name,
                        navigationPath: detail.path(),
                        isEditable: osbStepProgressBar.isRetrieveFlow
                            ? true
                            : step.isEditable,
                        hasChanged: osbStepProgressBar.isRetrieveFlow
                            ? true
                            : step.hasChanged,
                    };
                }
                return step;
            }
        );

        setOSBStepProgressBarPayload({
            progressBarDetails: updatedProgressBarDetails,
            isContentLoaded: true,
        });
    };

    useEffect(() => {
        if (!osbStepProgressBar.isContentLoaded) {
            const fetchContent = async () => {
                const result = await callContentService('osb-app');
                if (result) {
                    const stepNameList = getStepNameList(result.elements);
                    updateProgressBarDetails(stepNameList);
                }
            };
            fetchContent();
        }
    }, [
        osbStepProgressBar.isContentLoaded,
        callContentService,
        setOSBStepProgressBarPayload,
    ]);

    useEffect(() => {
        if (serverSideService.isClientSide()) {
            const handleTop = () => {
                setTop(
                    document.getElementById('osbBannerId')?.clientHeight ?? 0
                );
            };
            const handleResize = () => {
                setIsMobile(window.innerWidth <= 768);
            };
            window.addEventListener('scroll', handleTop);
            window.addEventListener('resize', handleTop);
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('scroll', handleTop);
                window.removeEventListener('resize', handleTop);
                window.removeEventListener('resize', handleResize);
            };
        }
    }, []);

    const updateProgressBar = (stepIndex: number) => {
        const currentStep = osbStepProgressBar.progressBarDetails[stepIndex];
        if (!currentStep.isActive && !currentStep.hasChanged) {
            return;
        }
        const updatedDetails = osbStepProgressBar.progressBarDetails.map(
            (step, index) => {
                if (index === stepIndex) {
                    return {
                        ...step,
                        isActive: true,
                        hasChanged: false,
                        isEditable: true,
                    };
                } else if (step.isActive) {
                    return {
                        ...step,
                        isActive: false,
                        hasChanged: true,
                    };
                } else {
                    return step;
                }
            }
        );

        setOSBStepProgressBarPayload({
            progressBarDetails: updatedDetails,
        });

        history.push(currentStep.navigationPath);
    };

    const getStepClass = (step: StepProgressBarDetail): string => {
        if (step.isActive) return 'active';
        if (step.hasChanged) return 'changed';
        if (step.isEditable) return 'editable';
        return '';
    };

    const activeStep = osbStepProgressBar.progressBarDetails.find(
        step => step.isActive
    );

    return (
        <div
            className="osb-v3-step-progress-bar-container"
            style={{ top }}
            id="step-progress-bar-container"
        >
            <ul className="step-progress-bar" id="progress-bar-steps">
                {osbStepProgressBar.progressBarDetails.map((step, index) => (
                    <li
                        key={index}
                        className={`step ${getStepClass(step)}`}
                        onClick={() => updateProgressBar(index)}
                        onKeyDown={e => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                updateProgressBar(index);
                            }
                        }}
                        tabIndex={0}
                    >
                        <div className="step-count-outer">
                            <div className="step-count">{index + 1}</div>
                            <div
                                className={`step-label ${
                                    isMobile && index === 0 && activeStep
                                        ? 'mobile-active-label'
                                        : ''
                                }`}
                            >
                                {isMobile && index === 0 && activeStep ? (
                                    activeStep.stepName
                                ) : !isMobile ? (
                                    step.stepName
                                ) : (
                                    <span>&nbsp;</span>
                                )}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default OSBProgressBar;
