import React, { useEffect } from 'react';
import { PopularVideoTile } from './popular-video-tile';
import './how-to-popular-video-tiles.scss';
import { Video } from '../../../../../services/brightcove-video-service/brightcove-video-data';
import { HeadTags } from '../../../../common/video/video';

interface Props {
    popularVideoItems: Video[];
    popularHowToVideosTitle?: string;
    className?: string;
    origin?: string;
    HeadTag?: HeadTags;
}

export const HowToPopularVideoTiles = (props: Props) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://players.brightcove.net/2379864788001/B1eFuwPXkG_default/index.min.js`;
        script.defer = true;
        document.body.appendChild(script);
    }, []);

    const popularVideoList = (videos: Video[]) => (
        <div className="tile-grid">
            {videos.map(
                (item: Video) =>
                    item.custom_fields && (
                        <div key={item.id} id={item.id}>
                            <PopularVideoTile
                                accountId={item.account_id}
                                videoId={item.id}
                                categoryName={item.custom_fields.category1}
                                make={item.custom_fields.make}
                                year={item.custom_fields.year}
                                model={item.custom_fields.model}
                                name={item.name}
                                origin={props.origin}
                                headTag={props.HeadTag}
                                seoKey={item.seoKey}
                            />
                        </div>
                    )
            )}
        </div>
    );

    const pageHeader = (popularHowToVideosTitle: string | undefined) => {
        return (
            <div className="popular-how-to-videos">
                <div>
                    <h2
                        className="popular-how-to-video-title"
                        data-testid="popular-video-header"
                    >
                        {popularHowToVideosTitle}
                    </h2>
                </div>
            </div>
        );
    };

    return (
        <>
            {props.origin === 'category-videos' && (
                <div
                    className={`popular-how-to-video-tiles-container ${props.className}`}
                >
                    {popularVideoList(props.popularVideoItems)}
                </div>
            )}
            {props.popularHowToVideosTitle &&
                props.popularVideoItems.length > 0 && (
                    <div
                        className={`popular-how-to-video-tiles-container ${props.className}`}
                    >
                        {pageHeader(props.popularHowToVideosTitle)}
                        {popularVideoList(props.popularVideoItems)}
                    </div>
                )}
        </>
    );
};
