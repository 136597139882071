import { useEffect } from 'react';
import './vehicle-health.scss';
import { PageNotFoundView } from '../../../views/page-not-found-view/page-not-found-view';
import { useVehicleHealthPageComponents } from './components/vehicle-health-page-components';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { SmartTileGrid } from '..';
import { VHAAlertList } from './components/vha-alert-list/vha-alert-list';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import { SupportTrio } from '../../common/support-trio/support-trio';
import { useAnalytics } from '../../../hooks/use-analytics';
import { FMCard, FMCarousel } from '@own/fds-react';
import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';
import { NewAnalyticsService } from '../../../services/new-analytics-service/new-analytics-service';
import { URLUtil } from '../../utils/url-util/url-util';
import { useHistory } from 'react-router-dom';
interface Props {
    vehicleAttributes: VehicleAttributes;
    uomDistance: number;
    userSelectedVin: string | null;
    handleBreadCrumb: (pageTitle: string) => void;
}

export const VehicleHealth = (props: Props) => {
    const history = useHistory();
    const [fireEvents] = useAnalytics();
    const appConfiguration = new AppConfigurationService().getAppConfiguration();
    const [
        vehicleHealthComponent,
        articleCarousel,
        vehicleHealthPageComponents,
        smarttilesContent,
    ] = useVehicleHealthPageComponents();

    const isContentAvailable =
        vehicleHealthComponent && !!Object.keys(vehicleHealthComponent).length;
    const { vehicleAttributes } = props;

    const triggerVehicleHealthPageLoadAnalytics = () => {
        fireEvents(['vehicle-health'], 'vehicle-health', undefined, false);
    };

    useEffect(() => {
        triggerVehicleHealthPageLoadAnalytics();
        props.handleBreadCrumb(vehicleHealthComponent?.vehicleHealthTitle);
    }, [vehicleHealthComponent]);

    function handleFMCardClick(item: FMCardProps, eventName: string) {
        return async () => {
            const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
                item.ctaHref || ''
            );
            eventName &&
                !referralExit &&
                fireEvents(
                    eventName,
                    undefined,
                    {
                        ctaType: item.ctaLabel,
                    },
                    false
                );
            NewAnalyticsService.fireReferralExitEventBasedOnUrl(
                item.ctaHref || '',
                fireEvents
            );
            if (item.ctaHref && URLUtil.isRelativeUrl(item.ctaHref)) {
                history.push(item.ctaHref || '');
            }
        };
    }

    return (
        <div className="vehicle-health-page-wrapper">
            {isContentAvailable ? (
                <>
                    <VHAAlertList
                        content={vehicleHealthComponent}
                        vehicleAttributes={vehicleAttributes}
                    />
                    {!articleCarousel?.hide && articleCarousel?.cards && (
                        <div className="vh-article-carousel">
                            <FMCarousel
                                items={articleCarousel?.cards}
                                render={(item: FMCardProps) => (
                                    <FMCard
                                        ctaOnClick={handleFMCardClick(
                                            item,
                                            'vehicle-health-keep-your-vehicle-running-cta-onclick'
                                        )}
                                        {...item}
                                        imageSrc={`${process.env.REACT_APP_AEM_BASE_URL}${item.imageSrc}`}
                                        ctaType={'secondary'}
                                        ctaTheme={'light'}
                                    >
                                        {item?.children}
                                    </FMCard>
                                )}
                                {...articleCarousel}
                            />
                        </div>
                    )}
                    {smarttilesContent && (
                        <SmartTileGrid
                            page="Vehicle Health Alerts"
                            vehicleAttributes={vehicleAttributes}
                            className={'information-grid'}
                            section={'information-grid'}
                            smartTileContent={smarttilesContent}
                            enableCarousel={true}
                        />
                    )}
                    {Array.isArray(vehicleHealthPageComponents) &&
                        vehicleHealthPageComponents.length > 0 &&
                        vehicleHealthPageComponents}
                    {appConfiguration.threeLetterCountryCode !== 'can' &&
                        appConfiguration.threeLetterCountryCode !== 'mex' &&
                        appConfiguration.threeLetterCountryCode !== 'usa' && (
                            <SupportTrio
                                folder="common"
                                experienceFragmentName="support-trio"
                            />
                        )}
                </>
            ) : (
                <PageNotFoundView />
            )}
        </div>
    );
};
