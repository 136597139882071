import React from 'react';
import DateTimeUtil from '../../../utils/date-time-util/date-time-util';
import MmotaModalLink from '../mmota-modal-link/mmota-modal-link';
import { Modal } from '../../../common/modal/modal';
import { MMOTAStatusResponse } from '../../../../services/mmota-status-service/mmota-status.interface';
import {
    DSL_MMOTA_STATUS,
    MMOTAUtil,
    SETTINGS_DISABLED,
    USER_DISALLOWED,
} from '../mmota-util';
import './mmota-modal.scss';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import { BRAND } from '../../../../constants';
import { FMButton, FMModal } from '@own/fds-react';

interface Props {
    vin?: string;
    isVisible: boolean;
    onClose: () => void;
    dynamicCTAPath?: string;
    otaStatus: MMOTAStatusResponse;
}

const MMOTAModal = (props: Props) => {
    const appConfig = new AppConfigurationService().getAppConfiguration();
    const currentVersion = appConfig.brand === BRAND.lincoln.LONG_NAME;
    const {
        summary,
        mmotaAlertsDetails,
        tappsData,
        ccsStatus,
        releaseNotes,
        error,
        status,
    } = props.otaStatus;
    const mmotaUtil = new MMOTAUtil();
    const dateTimeUtil = new DateTimeUtil();
    const mmotaStatusModalContent = mmotaUtil.mmotaStatusModalContent;

    const mmotaAlertsDetail = mmotaAlertsDetails?.[0];
    const currentStatus = summary?.alertDescription ?? status?.errorDescription;
    const { date, time } = dateTimeUtil.parseDateTime(
        mmotaAlertsDetail?.dateTimeStamp,
        true
    );
    const updateDisplayTime =
        mmotaAlertsDetail?.updateDisplayTime?.toString() ?? '45';
    const scheduleTypeMap: { [key: string]: string } = {
        none: mmotaStatusModalContent?.recurringScheduleNotSetText ?? 'Not Set',
        recurring: mmotaUtil.getParsedRecurringDateTime(
            tappsData,
            mmotaStatusModalContent?.showInMeridiemTime
        ),
    };
    const parsedRecurringDateTime =
        scheduleTypeMap[tappsData?.scheduleType?.toLowerCase() || ''];
    const headerDescription = mmotaStatusModalContent?.updateReminderStateDesc?.replace(
        '%date%',
        mmotaUtil.getParsedRecurringDateTime(
            tappsData,
            mmotaStatusModalContent?.showInMeridiemTime
        )
    );
    const updateNotesDescription =
        releaseNotes ?? mmotaStatusModalContent?.releaseNotesDesc ?? '';

    const statusMap: {
        [key: string]: {
            header: string | undefined;
            description: string | undefined;
            releaseNotesAvailable: boolean;
        };
    } = {
        [USER_DISALLOWED]: {
            header: mmotaStatusModalContent?.accessUnavailableStateTitle,
            description: mmotaStatusModalContent?.accessUnavailableStateDesc,
            releaseNotesAvailable: false,
        },
        [DSL_MMOTA_STATUS.upToDate]: {
            header: mmotaStatusModalContent?.upToDateStatusTitle,
            description: mmotaStatusModalContent?.upToDateStatusDesc,
            releaseNotesAvailable: false,
        },
        [DSL_MMOTA_STATUS.updateSuccessful]: {
            header: mmotaStatusModalContent?.successStatusTitle,
            description: mmotaStatusModalContent?.successStatusDesc,
            releaseNotesAvailable: true,
        },
        [DSL_MMOTA_STATUS.updateWillRetry]: {
            header: mmotaStatusModalContent?.retryStateTitle,
            description: mmotaStatusModalContent?.retryStateDesc,
            releaseNotesAvailable: true,
        },
        [DSL_MMOTA_STATUS.updateInProgress]: {
            header: mmotaStatusModalContent?.inProgressStateTitle,
            description: mmotaStatusModalContent?.inProgressStateDesc?.replace(
                '%time%',
                updateDisplayTime
            ),
            releaseNotesAvailable: true,
        },
        [DSL_MMOTA_STATUS.updateReminder]: {
            header: mmotaStatusModalContent?.updateReminderStateTitle,
            description: headerDescription?.replace(
                '%time%',
                updateDisplayTime
            ),
            releaseNotesAvailable: true,
        },
        [DSL_MMOTA_STATUS.updatePending]: {
            header: mmotaStatusModalContent?.updatePendingStateTitle,
            description: mmotaStatusModalContent?.updatePendingStateDesc?.replace(
                '%date%',
                [date, mmotaStatusModalContent?.atTranslationText, time]
                    .join(' ')
                    .trim()
            ),
            releaseNotesAvailable: true,
        },
        [DSL_MMOTA_STATUS.updateUnsuccessfullServiceRecommended]: {
            header: mmotaStatusModalContent?.updateUnsuccessfulStateTitle,
            description: mmotaStatusModalContent?.updateUnsuccessfulStateDesc,
            releaseNotesAvailable: true,
        },
        [DSL_MMOTA_STATUS.updateUnsuccessfullWillTryAgain]: {
            header: mmotaStatusModalContent?.updateUnsuccessfulStateTitle,
            description: mmotaStatusModalContent?.updateUnsuccessfulStateDesc,
            releaseNotesAvailable: true,
        },
        [DSL_MMOTA_STATUS.vehicleInoperable]: {
            header: mmotaStatusModalContent?.inoperableStateTitle,
            description: mmotaStatusModalContent?.inoperableStateDesc,
            releaseNotesAvailable: true,
        },
        [SETTINGS_DISABLED]: {
            header: mmotaStatusModalContent?.settingsDisabledStateTitle,
            description: mmotaStatusModalContent?.settingsDisabledStateDesc,
            releaseNotesAvailable: false,
        },
    };

    const isCcsDisabled = ccsStatus?.ccsVehicleData?.toLowerCase() !== 'on';
    const isAsuDisabled = tappsData?.asuSettingsState?.toLowerCase() !== 'on';

    const settingsStatus =
        !currentStatus && (isCcsDisabled || isAsuDisabled)
            ? SETTINGS_DISABLED
            : currentStatus;
    const isAccessAvailable = error?.message !== USER_DISALLOWED;
    const statusKey = isAccessAvailable ? settingsStatus : USER_DISALLOWED;
    const statusInfo = statusMap[statusKey];
    const { header, description = '', releaseNotesAvailable } = statusInfo;

    const settingsState = isAsuDisabled
        ? mmotaStatusModalContent?.automaticUpdatesOffText ?? 'Off'
        : mmotaStatusModalContent?.automaticUpdatesOnText ?? 'On';

    const displayHowToChangeOrSetContent = [
        DSL_MMOTA_STATUS.upToDate,
        DSL_MMOTA_STATUS.updateUnsuccessfullServiceRecommended,
        DSL_MMOTA_STATUS.updateUnsuccessfullWillTryAgain,
        DSL_MMOTA_STATUS.updateReminder,
    ].includes(currentStatus);

    return (
        <>
            {currentVersion ? (
                <Modal
                    name="mmota-modal"
                    onClose={props.onClose}
                    isVisible={props.isVisible}
                >
                    <h2 className="mmota-modal-header">{header}</h2>
                    <div
                        data-testid="mmota-header-description"
                        className="mmota-list-body"
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                    />
                    {mmotaStatusModalContent && (
                        <>
                            {currentStatus ===
                                DSL_MMOTA_STATUS.updatePending && (
                                <>
                                    <MmotaModalLink
                                        class="inline first"
                                        href={
                                            mmotaStatusModalContent.connectToWifiLinkPath
                                        }
                                        ariaLabel={
                                            mmotaStatusModalContent.connectToWifiLinkText
                                        }
                                        content={
                                            mmotaStatusModalContent.connectToWifiLinkText
                                        }
                                    />

                                    <MmotaModalLink
                                        class="inline middle"
                                        href={
                                            mmotaStatusModalContent.acceptUpdateLinkPath
                                        }
                                        ariaLabel={
                                            mmotaStatusModalContent.acceptUpdateLinkText
                                        }
                                        content={
                                            mmotaStatusModalContent.acceptUpdateLinkText
                                        }
                                    />

                                    <MmotaModalLink
                                        class="inline last"
                                        href={
                                            mmotaStatusModalContent.setScheduleLinkPath
                                        }
                                        ariaLabel={
                                            mmotaStatusModalContent.setScheduleLinkText
                                        }
                                        content={
                                            mmotaStatusModalContent.setScheduleLinkText
                                        }
                                    />
                                </>
                            )}
                            <MmotaModalLink
                                href={
                                    mmotaStatusModalContent.getSupportLinkPath
                                }
                                ariaLabel={
                                    mmotaStatusModalContent.getSupportLinkText
                                }
                                content={
                                    mmotaStatusModalContent.getSupportLinkText
                                }
                            />
                            {mmotaStatusModalContent.releaseNotesLinkText && (
                                <MmotaModalLink
                                    href={
                                        mmotaStatusModalContent.releaseNotesLinkPath
                                    }
                                    ariaLabel={
                                        mmotaStatusModalContent.releaseNotesLinkText
                                    }
                                    content={
                                        mmotaStatusModalContent.releaseNotesLinkText
                                    }
                                />
                            )}
                            {!isAccessAvailable && (
                                <MmotaModalLink
                                    href={
                                        mmotaStatusModalContent.howToActivateLinkPath
                                    }
                                    ariaLabel={
                                        mmotaStatusModalContent.howToActivateLinkText
                                    }
                                    content={
                                        mmotaStatusModalContent.howToActivateLinkText
                                    }
                                />
                            )}
                            <hr />
                            {settingsState &&
                                currentStatus !==
                                    DSL_MMOTA_STATUS.updateInProgress && (
                                    <p>
                                        {`${mmotaStatusModalContent.otaSettingsText}
                                ${settingsState}`}
                                    </p>
                                )}
                            {parsedRecurringDateTime &&
                                currentStatus !==
                                    DSL_MMOTA_STATUS.updateInProgress && (
                                    <p>
                                        {`${mmotaStatusModalContent.recurringScheduleText}
                                ${parsedRecurringDateTime}`}
                                    </p>
                                )}
                            {displayHowToChangeOrSetContent && (
                                <MmotaModalLink
                                    href={
                                        mmotaStatusModalContent.howToChangeOrSetLinkPath
                                    }
                                    ariaLabel={
                                        mmotaStatusModalContent.howToChangeOrSetLinkText
                                    }
                                    content={
                                        mmotaStatusModalContent.howToChangeOrSetLinkText
                                    }
                                />
                            )}
                            {currentStatus ===
                                DSL_MMOTA_STATUS.updateWillRetry && (
                                <MmotaModalLink
                                    href={
                                        mmotaStatusModalContent.howToChangeOrSetLinkPath
                                    }
                                    ariaLabel={
                                        mmotaStatusModalContent.howToChangeLinkText
                                    }
                                    content={
                                        mmotaStatusModalContent.howToChangeLinkText
                                    }
                                />
                            )}
                            {isCcsDisabled && isAccessAvailable && (
                                <MmotaModalLink
                                    href={
                                        mmotaStatusModalContent.howToChangeSettingsLinkPath
                                    }
                                    ariaLabel={
                                        mmotaStatusModalContent.howToChangeOTASettingsLinkText
                                    }
                                    content={
                                        mmotaStatusModalContent.howToChangeOTASettingsLinkText
                                    }
                                />
                            )}
                            {isCcsDisabled && isAccessAvailable && (
                                <MmotaModalLink
                                    href={
                                        mmotaStatusModalContent.howToChangeSettingsLinkPath
                                    }
                                    ariaLabel={
                                        mmotaStatusModalContent.howToChangeSettingsLinkText
                                    }
                                    content={
                                        mmotaStatusModalContent.howToChangeSettingsLinkText
                                    }
                                />
                            )}
                            {
                                <>
                                    {currentStatus ===
                                        DSL_MMOTA_STATUS.updateSuccessful &&
                                        date &&
                                        time && (
                                            <p className="top-padding">
                                                {
                                                    mmotaStatusModalContent.lastSuccessfulText
                                                }
                                                <br />
                                                {date}
                                            </p>
                                        )}

                                    {releaseNotesAvailable && (
                                        <div>
                                            <h3 className="mmota-updates__header">
                                                {
                                                    mmotaStatusModalContent.updateNotesTitle
                                                }
                                            </h3>
                                            <hr className="mmota-updates__header-hr" />
                                            <div
                                                className="mmota-updates__notes"
                                                dangerouslySetInnerHTML={{
                                                    __html: updateNotesDescription,
                                                }}
                                            />
                                        </div>
                                    )}
                                </>
                            }
                        </>
                    )}
                </Modal>
            ) : (
                <FMModal
                    name="mmota-modal"
                    onClose={props.onClose}
                    isVisible={props.isVisible}
                    closeButtonAriaLabel={'close'}
                    title={header}
                    subtitle={
                        <div
                            data-testid="mmota-header-description"
                            dangerouslySetInnerHTML={{
                                __html: description,
                            }}
                        />
                    }
                >
                    {mmotaStatusModalContent && (
                        <>
                            {currentStatus ===
                                DSL_MMOTA_STATUS.updatePending && (
                                <div
                                    className={'fm-update-pending-btn-wrapper'}
                                >
                                    <FMButton
                                        className={'inline-first'}
                                        href={
                                            mmotaStatusModalContent.connectToWifiLinkPath
                                        }
                                        ariaLabel={
                                            mmotaStatusModalContent.connectToWifiLinkText
                                        }
                                        label={
                                            mmotaStatusModalContent.connectToWifiLinkText
                                        }
                                        type={'tertiary'}
                                    />

                                    <FMButton
                                        className={'inline-middle'}
                                        href={
                                            mmotaStatusModalContent.acceptUpdateLinkPath
                                        }
                                        ariaLabel={
                                            mmotaStatusModalContent.acceptUpdateLinkText
                                        }
                                        label={
                                            mmotaStatusModalContent.acceptUpdateLinkText
                                        }
                                        type={'tertiary'}
                                    />

                                    <FMButton
                                        className={'inline-last'}
                                        href={
                                            mmotaStatusModalContent.setScheduleLinkPath
                                        }
                                        ariaLabel={
                                            mmotaStatusModalContent.setScheduleLinkText
                                        }
                                        label={
                                            mmotaStatusModalContent.setScheduleLinkText
                                        }
                                        type={'tertiary'}
                                    />
                                </div>
                            )}
                            {!isAccessAvailable && (
                                <FMButton
                                    href={
                                        mmotaStatusModalContent.howToActivateLinkPath
                                    }
                                    ariaLabel={
                                        mmotaStatusModalContent.howToActivateLinkText
                                    }
                                    label={
                                        mmotaStatusModalContent.howToActivateLinkText
                                    }
                                    type={'tertiary'}
                                />
                            )}
                            <div className={'fm-mmota-button-wrapper'}>
                                {displayHowToChangeOrSetContent && (
                                    <FMButton
                                        href={
                                            mmotaStatusModalContent.howToChangeOrSetLinkPath
                                        }
                                        ariaLabel={
                                            mmotaStatusModalContent.howToChangeOrSetLinkText
                                        }
                                        label={
                                            mmotaStatusModalContent.howToChangeOrSetLinkText
                                        }
                                        type={'tertiary'}
                                    />
                                )}
                                {currentStatus ===
                                    DSL_MMOTA_STATUS.updateWillRetry && (
                                    <FMButton
                                        href={
                                            mmotaStatusModalContent.howToChangeOrSetLinkPath
                                        }
                                        ariaLabel={
                                            mmotaStatusModalContent.howToChangeLinkText
                                        }
                                        label={
                                            mmotaStatusModalContent.howToChangeLinkText
                                        }
                                        type={'tertiary'}
                                    />
                                )}
                                {isCcsDisabled &&
                                    error?.message !== USER_DISALLOWED && (
                                        <FMButton
                                            href={
                                                mmotaStatusModalContent.howToChangeSettingsLinkPath
                                            }
                                            ariaLabel={
                                                mmotaStatusModalContent.howToChangeOTASettingsLinkText
                                            }
                                            label={
                                                mmotaStatusModalContent.howToChangeOTASettingsLinkText
                                            }
                                            type={'tertiary'}
                                        />
                                    )}
                                {isCcsDisabled &&
                                    error?.message !== USER_DISALLOWED && (
                                        <FMButton
                                            href={
                                                mmotaStatusModalContent.howToChangeSettingsLinkPath
                                            }
                                            ariaLabel={
                                                mmotaStatusModalContent.howToChangeSettingsLinkText
                                            }
                                            label={
                                                mmotaStatusModalContent.howToChangeSettingsLinkText
                                            }
                                            type={'tertiary'}
                                        />
                                    )}
                                <FMButton
                                    href={
                                        mmotaStatusModalContent.getSupportLinkPath
                                    }
                                    ariaLabel={
                                        mmotaStatusModalContent.getSupportLinkText
                                    }
                                    label={
                                        mmotaStatusModalContent.getSupportLinkText
                                    }
                                    type={'tertiary'}
                                />
                            </div>
                            <hr />
                            {settingsState &&
                                currentStatus !==
                                    DSL_MMOTA_STATUS.updateInProgress && (
                                    <p>
                                        {`${mmotaStatusModalContent.otaSettingsText}
                                ${settingsState}`}
                                    </p>
                                )}
                            {parsedRecurringDateTime &&
                                currentStatus !==
                                    DSL_MMOTA_STATUS.updateInProgress && (
                                    <p>
                                        {`${mmotaStatusModalContent.recurringScheduleText}
                                ${parsedRecurringDateTime}`}
                                    </p>
                                )}
                            {
                                <>
                                    {currentStatus ===
                                        DSL_MMOTA_STATUS.updateSuccessful &&
                                        date &&
                                        time && (
                                            <p className="top-padding">
                                                {
                                                    mmotaStatusModalContent.lastSuccessfulText
                                                }
                                                <br />
                                                {date}
                                            </p>
                                        )}

                                    {releaseNotesAvailable && (
                                        <div
                                            className={
                                                'fm-update-notes-wrapper'
                                            }
                                        >
                                            <h3 className="mmota-updates__header">
                                                {
                                                    mmotaStatusModalContent.updateNotesTitle
                                                }
                                            </h3>
                                            <hr className="mmota-updates__header-hr" />
                                            <div
                                                className="mmota-updates__notes"
                                                dangerouslySetInnerHTML={{
                                                    __html: updateNotesDescription,
                                                }}
                                            />
                                        </div>
                                    )}
                                </>
                            }
                        </>
                    )}
                </FMModal>
            )}
        </>
    );
};

export default MMOTAModal;
