import React, { useEffect, useState } from 'react';
import './maintenance-schedule-landing.scss';
import { useMaintenanceScheduleLandingContent } from '../hooks/use-maintenance-schedule-landing-content';
import useUserProfile from '../../../../hooks/use-user-profile';
import { VehicleAttributes } from '../../../../models/vehicle-attributes';
import { EnterYourVinSection } from './enter-your-vin-section/enter-your-vin-section';
import { LoginSection } from '../../login-register/login-register';
import { useLoginOrRegisterContent } from '../../login-register/hooks/use-login-register-content';
import { VehicleFromGarage } from './vehicle-from-garage/vehicle-from-garage';
import { SupportTrio } from '../../../common/support-trio/support-trio';
import { useGenericAppErrorContent } from '../../error-message/hook/use-generic-app-error-content';
import { ErrorMessage } from '../../error-message/error-message';
import { useAnalytics } from '../../../../hooks/use-analytics';
import { BreadcrumbWrapperReimagine } from '../../../../components/common/breadcrumb-wrapper-reimagine/breadcrumb-wrapper-reimagine';
import { ViewTemplate } from '../../view-template/view-template';
import { PageType } from '../../view-template/page-type';
import isWebviewCheck from '../../../utils/webviewCheck/webviewCheck';
import { useExperienceContent } from '../../../../hooks/use-server-content';
import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';
import { FMCard, FMCarousel } from '@own/fds-react';
import { FeatureCardsFragment } from '../../vehicle-portal/services/feature-cards/feature-card-article-service';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import { NewAnalyticsService } from '../../../../services/new-analytics-service/new-analytics-service';
import { URLUtil } from '../../../utils/url-util/url-util';
import { useHistory } from 'react-router-dom';

interface Props {
    isAuthenticated: string;
    loginClick: (action: string) => void;
    goToMaintenanceScheduleViewDetails: (vin: string) => void;
    page: PageType;
}

export const MaintenanceScheduleLanding = (props: Props) => {
    const history = useHistory();
    const genericAppErrorContent = useGenericAppErrorContent();
    const [showError, setShowError] = useState<boolean>(false);
    const maintenanceScheduleLandingContent = useMaintenanceScheduleLandingContent();
    const isContentAvailable = !!Object.keys(maintenanceScheduleLandingContent)
        .length;
    const loginOrRegisterContent = useLoginOrRegisterContent();
    const [vehiclesData, setVehiclesData] = useState<VehicleAttributes[]>([]);
    const profile = useUserProfile();

    const isWebview = isWebviewCheck();

    const {
        maintenanceSchedulePageHeadline,
        maintenanceScheduleDescription,
        enterYourVehicleDetailFormHeadline,
        vinOrRegInputFieldLabel,
        mileageInputFieldLabel,
        enterYourVehicleDetailsCTALabel,
        orSeparatorText,
        maintenanceScheduleNotFoundErrorMessage,
        maintenanceSchedulePath,
        enterYourVehicleDetailsTooltipHeadline,
        enterYourVehicleDetailsTooltipContent,
        enterYourVehicleDetailsTooltipAriaLabel,
        chooseYourVehicleFormHeadline,
        yourVehicleFieldLabel,
        chooseYourVehicleCta,
    } = maintenanceScheduleLandingContent;
    const {
        loginOrRegisterFormHeading,
        loginOrRegisterFormDescription,
        signinButtonLabel,
        registerButtonLabel,
    } = loginOrRegisterContent;
    const { genericErrorMessage } = genericAppErrorContent;
    const maintenanceScheduleBreadCrumb = maintenanceSchedulePageHeadline?.toLowerCase();
    const maintenanceScheduleBreadCrumbTitle = maintenanceScheduleBreadCrumb?.replace(
        /(?<=^|\s)\w/g,
        letter => letter.toUpperCase()
    );

    const [fireEvents] = useAnalytics();
    const triggerLoadAnalytics = () => {
        fireEvents(
            ['maintenance-schedule-landing'],
            undefined,
            {
                fieldName: 'your vehicle',
            },
            false
        );
    };
    const { isAuthenticated } = props;

    useEffect(() => {
        if (profile) {
            setVehiclesData(
                profile.vehicles.map(vehicle => {
                    return {
                        year: parseInt(vehicle.modelYear, 10),
                        make: vehicle.make,
                        model: vehicle.modelName,
                        vin: vehicle.vin,
                        uomSpeed: profile.profile.uomSpeed,
                        nickName: vehicle.nickName,
                        modelYear: vehicle.modelYear,
                        vehicleMake: vehicle.make,
                        modelName: vehicle.modelName,
                    };
                })
            );
        }
        triggerLoadAnalytics();
    }, [profile]);

    const isUserWithCars =
        isAuthenticated === 'loggedIn' && vehiclesData.length > 0;

    const [authenticatedContent] = useExperienceContent<FeatureCardsFragment>(
        'common',
        'maintenance-schedule-authenticated',
        'articleCarousel'
    );

    const [unAuthenticatedContent] = useExperienceContent<FeatureCardsFragment>(
        'common',
        'maintenance-schedule-unauthenticated',
        'articleCarousel'
    );

    const appConfiguration = new AppConfigurationService().getAppConfiguration();

    function handleFMCardClick(item: FMCardProps, eventName: string) {
        return async () => {
            const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
                item.ctaHref || ''
            );
            eventName &&
                !referralExit &&
                fireEvents(
                    eventName,
                    undefined,
                    {
                        ctaType: item.ctaLabel,
                    },
                    false
                );
            NewAnalyticsService.fireReferralExitEventBasedOnUrl(
                item.ctaHref || '',
                fireEvents
            );
            if (item.ctaHref && URLUtil.isRelativeUrl(item.ctaHref)) {
                history.push(item.ctaHref || '');
            }
        };
    }

    const keepRunningVehicleContent = () => {
        const isLoggedIn = isAuthenticated === 'loggedIn';
        const cardsContent = isLoggedIn
            ? authenticatedContent?.cards
            : unAuthenticatedContent?.cards;
        const containerData = isLoggedIn
            ? authenticatedContent
            : unAuthenticatedContent;

        return (
            cardsContent && (
                <div className="maintenance-schedule-article-carousel">
                    <FMCarousel
                        items={cardsContent || []}
                        render={(item: FMCardProps) => (
                            <FMCard
                                {...item}
                                imageSrc={`${process.env.REACT_APP_AEM_BASE_URL}${item.imageSrc}`}
                                ctaType={'primary'}
                                ctaTheme={'light'}
                                ctaOnClick={handleFMCardClick(
                                    item,
                                    'maintenance-schedule-keep-your-vehicle-running-cta-onclick'
                                )}
                            >
                                {item?.children}
                            </FMCard>
                        )}
                        {...containerData}
                    />
                </div>
            )
        );
    };

    return (
        <>
            <ViewTemplate
                page={props.page}
                className="trailer-checklist-view"
                hideLinksList={true}
                hideSearchBar={isWebview}
            >
                {isContentAvailable && (
                    <>
                        {isWebview ? (
                            ''
                        ) : (
                            <BreadcrumbWrapperReimagine
                                currentPage={maintenanceScheduleBreadCrumbTitle}
                                hashId="support"
                            />
                        )}
                        <section className="maintenance-schedule-landing-page">
                            <section className="maintenance-schedule-landing-heading-section">
                                <h1 className="maintenance-schedule-landing-header">
                                    {' '}
                                    {maintenanceSchedulePageHeadline}{' '}
                                </h1>
                                {!showError && (
                                    <div
                                        className="maintenance-schedule-landing-description"
                                        dangerouslySetInnerHTML={{
                                            __html: maintenanceScheduleDescription,
                                        }}
                                    />
                                )}
                            </section>
                            {showError ? (
                                <div className="maintenance-error">
                                    <ErrorMessage
                                        errorMessage={genericErrorMessage}
                                    />
                                </div>
                            ) : (
                                <section className="enter-your-vin-combined-wrapper">
                                    <div className="vehicle-details-wrapper">
                                        {(isUserWithCars ||
                                            isAuthenticated ===
                                                'notLoggedIn') && (
                                            <>
                                                {' '}
                                                <div className="vehicle-details-form-left">
                                                    {isUserWithCars ? (
                                                        <VehicleFromGarage
                                                            vehiclesData={
                                                                vehiclesData
                                                            }
                                                            showErrorPage={isError =>
                                                                setShowError(
                                                                    isError
                                                                )
                                                            }
                                                            chooseYourVehicleFormHeadline={
                                                                chooseYourVehicleFormHeadline
                                                            }
                                                            yourVehicleFieldLabel={
                                                                yourVehicleFieldLabel
                                                            }
                                                            chooseYourVehicleCta={
                                                                chooseYourVehicleCta
                                                            }
                                                            maintenanceScheduleNotFoundErrorMessage={
                                                                maintenanceScheduleNotFoundErrorMessage
                                                            }
                                                            goToMaintenanceScheduleViewDetails={
                                                                props.goToMaintenanceScheduleViewDetails
                                                            }
                                                        />
                                                    ) : (
                                                        <LoginSection
                                                            contentLabels={{
                                                                loginOrRegisterFormHeading,
                                                                loginOrRegisterFormDescription,
                                                                signinButtonLabel,
                                                                registerButtonLabel,
                                                            }}
                                                            loginClick={
                                                                props.loginClick
                                                            }
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    className={`divider-section ${
                                                        isAuthenticated ===
                                                        'loggedIn'
                                                            ? 'divider-logged-in'
                                                            : ''
                                                    }`}
                                                >
                                                    <hr className="before-dash" />{' '}
                                                    <span>
                                                        {orSeparatorText}
                                                    </span>
                                                    <hr className="after-dash" />
                                                </div>
                                            </>
                                        )}
                                        <EnterYourVinSection
                                            contentLabels={{
                                                enterYourVehicleDetailFormHeadline,
                                                vinOrRegInputFieldLabel,
                                                mileageInputFieldLabel,
                                                enterYourVehicleDetailsCTALabel,
                                                maintenanceScheduleNotFoundErrorMessage,
                                                maintenanceSchedulePath,
                                                enterYourVehicleDetailsTooltipHeadline,
                                                enterYourVehicleDetailsTooltipContent,
                                                enterYourVehicleDetailsTooltipAriaLabel,
                                            }}
                                            showErrorPage={isError =>
                                                setShowError(isError)
                                            }
                                            goToMaintenanceScheduleViewDetails={
                                                props.goToMaintenanceScheduleViewDetails
                                            }
                                        />
                                    </div>
                                </section>
                            )}
                        </section>
                        {!isWebview &&
                        appConfiguration.threeLetterCountryCode === 'usa'
                            ? keepRunningVehicleContent()
                            : !isWebview && (
                                  <SupportTrio
                                      folder="common"
                                      experienceFragmentName="maintenance-schedule-support"
                                  />
                              )}
                    </>
                )}
            </ViewTemplate>
        </>
    );
};
