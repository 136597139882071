import React, { useContext } from 'react';

import './video-tile.scss';
import { BrightcoveProps, HeadTags } from '../../../../../common/video/video';
import { useAnalytics } from '../../../../../../hooks/use-analytics';
import { Heading } from '../../../../../common/heading/heading';
import { useServerData } from '../../../../../../hooks/use-server-data';
import BrightCoveService from '../../../../../../services/brightcove-service/brightcove-service';
import ServerContext from '../../../../../../contexts/serverContext';
import useAemVehicleModelData from '../../../../../../hooks/use-aem-vehicle-model-data';
import PopularVideoService from '../../../../../../views/how-to-video-view/service/popular-video-service';
import { HTV_VIDEO_NAME_REGEX } from '../../../../../../constants';

export const VideoTile = (props: BrightcoveProps) => {
    const [fireEvents] = useAnalytics();
    const { currentLanguageRegionCode, currentRegionCode, brand } = useContext(
        ServerContext
    );
    const serverContext = useContext(ServerContext);
    const baseRoot = serverContext.root
        ? serverContext.root.substring(0, serverContext.root.length - 1)
        : '';
    const yearTrim = () => {
        const yearList = props.year?.split(/[,|]/);
        if (yearList) {
            const trimmedYearList = yearList.map(element => element.trim());
            return trimmedYearList[0];
        }
    };

    const modelTrim = () => {
        const modelList = props.model?.split(/[,|]/);
        if (modelList) {
            const trimmedModelList = modelList.map(element => element.trim());
            return trimmedModelList[0];
        }
    };

    const vehicleYmmGroup = useAemVehicleModelData(
        brand,
        currentRegionCode,
        currentLanguageRegionCode,
        yearTrim() || '',
        modelTrim() || '',
        'PTS_MARKET_NAME'
    );

    const tileHref = () => {
        if (props.categoryName) {
            return `${baseRoot}/vehicle/${
                vehicleYmmGroup?.seoKey
            }/${PopularVideoService.yearTrim(
                vehicleYmmGroup?.year.toString() || '',
                vehicleYmmGroup?.year.toString() || ''
            )}/how-to-videos/video-library/${props.categoryName
                .toLowerCase()
                .split(' ')
                .join('-')}/${props.videoId}?name=${props.name
                ?.toLowerCase()
                .replace(HTV_VIDEO_NAME_REGEX, '')
                .trim()
                .split(' ')
                .join('-')}/`;
        }
    };

    const videoResponse = useServerData('videoResponse', async () => {
        return new BrightCoveService().getVideoDetails(
            props.videoId,
            props.accountId
        );
    })[0];

    const fireVideoTileAnalytics = () => {
        const videoOnClickCtaInfo = {
            videoName: props.name,
        };
        if (props.eventName) {
            fireEvents(props.eventName, undefined, videoOnClickCtaInfo, false);
        }
    };

    return (
        <>
            <div className={`popular-video-wrapper ${props.videoId}`}>
                <div className="popular-video-tile">
                    <a
                        className="popular-video-poster"
                        href={tileHref()}
                        onClick={() => fireVideoTileAnalytics()}
                    >
                        <div className="video-js video-poster">
                            <button
                                id={props.videoId}
                                className={`video-focus-${props.videoId}`}
                                aria-label={`Open video ${props.videoId}`}
                            >
                                <img
                                    className="vjs-poster"
                                    src={videoResponse?.data?.poster}
                                    alt={props.name || ''}
                                />
                            </button>
                        </div>
                    </a>

                    <div className="video-details">
                        <Heading
                            type={props.headTag ? props.headTag : HeadTags.H3}
                            className="video-name"
                        >
                            {props.name}
                        </Heading>
                    </div>
                </div>
            </div>
        </>
    );
};
