import React, { useState } from 'react';
import SupportFdsWrapper from '../../../support/components/support-fds-wrapper/support-fds-wrapper';
import { BreadcrumbsReimagine } from '../../sections/breadcrumbs-reimagine/breadcrumbs-reimagine';
import { ViewportUtil } from '../../utils/viewport-util/viewport-util';
import EllipsesIcon from '../../../assets/ellipses-icon.png';

interface Props {
    currentPage: string;
    hashId: string;
    type?: string;
    className?: string;
    title?: React.ReactNode;
    hideFirstBreadcrumb?: boolean;
}

export const BreadcrumbWrapperReimagine = (props: Props) => {
    const { currentPage, hashId, className, type, hideFirstBreadcrumb } = props;
    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);

    const { isMobileView } = ViewportUtil();

    const handleShowMoreInfo = () => {
        setShowMoreInfo(!showMoreInfo);
    };

    const getMobileDevice = (title: any) => {
        return (
            <>
                {showMoreInfo
                    ? title
                    : title && (
                          <span
                              onClick={handleShowMoreInfo}
                              className="ellipses-icon"
                          >
                              <img src={EllipsesIcon} />
                          </span>
                      )}
                {!showMoreInfo && title && (
                    <span className="back-slash">{'/'}</span>
                )}
            </>
        );
    };

    return (
        <>
            <SupportFdsWrapper className={className}>
                <div className={`breadcrumbs-wrapper breadcrumbAccordion`}>
                    <BreadcrumbsReimagine
                        currentPage={currentPage}
                        hashId={hashId}
                        type={type}
                        onClick={() => sessionStorage.removeItem('selectedVin')}
                        hideFirstBreadcrumb={hideFirstBreadcrumb}
                        isShowMoreInfo={showMoreInfo}
                    >
                        {!isMobileView
                            ? props?.title
                            : getMobileDevice(props?.title)}
                    </BreadcrumbsReimagine>
                </div>
            </SupportFdsWrapper>
        </>
    );
};
