import React, { useContext } from 'react';
import './popular-video-tile.scss';
import { BrightcoveProps, HeadTags } from '../../../../common/video/video';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { useServerData } from '../../../../../hooks/use-server-data';
import BrightCoveService from '../../../../../services/brightcove-service/brightcove-service';
import { Heading } from '../../../../common/heading/heading';
import PopularVideoService from '../../../../../views/how-to-video-view/service/popular-video-service';
import ServerContext from '../../../../../contexts/serverContext';
import { HTV_VIDEO_NAME_REGEX } from '../../../../../constants';

export const PopularVideoTile = (
    props: BrightcoveProps & { seoKey?: string }
) => {
    const { origin } = props;
    const [fireEvents] = useAnalytics();
    const serverContext = useContext(ServerContext);
    const baseRoot = serverContext.root
        ? serverContext.root.substring(0, serverContext.root.length - 1)
        : '';
    const categoryTitleTransform = (categoryTitle: string) => {
        const categoryText = categoryTitle.replace(/-/g, ' ');
        return categoryText.toUpperCase();
    };
    const videoResponse = useServerData('videoResponse', async () => {
        return new BrightCoveService().getVideoDetails(
            props.videoId,
            props.accountId
        );
    })[0];
    const tileHref = () => {
        if (props.seoKey && props.year && props.categoryName) {
            return `${baseRoot}/vehicle/${
                props.seoKey
            }/${PopularVideoService.yearTrim(
                props.year,
                props.year
            )}/how-to-videos/video-library/${props.categoryName
                .toLowerCase()
                .split(' ')
                .join('-')}/${props.videoId}?name=${props.name
                ?.toLowerCase()
                .replace(HTV_VIDEO_NAME_REGEX, '')
                .trim()
                .split(' ')
                .join('-')}/`;
        } else return '';
    };
    const videoName = props.name ? props.name : videoResponse?.data?.name;
    const thumbnail = props.thumbnail
        ? props.thumbnail
        : videoResponse?.data?.poster;

    const fireVideoTileAnalytics = () => {
        if (props.categoryName) {
            const videoOnClickCtaInfo = {
                categoryName: `${categoryTitleTransform(
                    props.categoryName
                ).toLowerCase()}`,
                videoName: props.name?.toLowerCase(),
            };
            if (origin === 'popular-videos') {
                fireEvents(
                    'dyf-new-htv-popular-cta-onclick-event',
                    undefined,
                    videoOnClickCtaInfo,
                    false
                );
            }
        }
    };

    return props.videoId ? (
        <>
            <div className={`popular-video-wrapper ${props.videoId}`}>
                <div className="popular-video-tile">
                    <a
                        className="popular-video-poster"
                        href={tileHref()}
                        onClick={() => fireVideoTileAnalytics()}
                    >
                        <div className="video-js video-poster">
                            <button
                                id={props.videoId}
                                className={`video-focus-${props.videoId}`}
                                aria-label={`Open video ${props.videoDetails?.videoTitle}`}
                            >
                                <img
                                    className="vjs-poster"
                                    src={thumbnail}
                                    alt={videoName || ''}
                                />
                            </button>
                        </div>
                    </a>

                    <div className="video-details">
                        <Heading
                            type={props.headTag ? props.headTag : HeadTags.H3}
                            className="video-name"
                        >
                            {videoName}
                        </Heading>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <></>
    );
};
