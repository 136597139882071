import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { FMTabProps, FMTabsProps } from '../../types/fm-tabs.types';
import { KEYBOARD_KEYS } from '../vehicleSelector/vehicleSelector.constants';
import './fm-tabs.scss';

const FMTabs = (props: FMTabsProps) => {
    const { panels } = props;
    const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);
    const [activeIndex, setActiveIndex] = useState<number>(0);

    const focusNext = () => {
        const nextIndex = (activeIndex + 1) % panels.length;
        setActiveIndex(nextIndex);
    };

    const focusPrevious = () => {
        const prevIndex = (activeIndex - 1 + panels.length) % panels.length;
        setActiveIndex(prevIndex);
    };

    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.code === KEYBOARD_KEYS.TAB) {
            const isShiftKeyDown = event.shiftKey;
            if (
                (isShiftKeyDown && activeIndex == 0) ||
                (!isShiftKeyDown && activeIndex == panels.length - 1)
            ) {
                return;
            }
            event.preventDefault();
            if (isShiftKeyDown) {
                focusPrevious();
            } else {
                focusNext();
            }
        } else if (event.code === KEYBOARD_KEYS.ARROW_LEFT) {
            focusPrevious();
        } else if (event.code === KEYBOARD_KEYS.ARROW_RIGHT) {
            focusNext();
        } else if (event.code === KEYBOARD_KEYS.HOME) {
            setActiveIndex(0);
        } else if (event.code === KEYBOARD_KEYS.END) {
            setActiveIndex(panels.length - 1);
        }
    };

    const handleTabChange = (index: number) => {
        setActiveIndex(index);
    };

    useEffect(() => {
        if (tabRefs.current[activeIndex]) {
            tabRefs.current[activeIndex]?.focus();
        }
    }, [activeIndex]);

    function getClassAndId(type: string, index: number) {
        const className = [
            `fm-tabs__${type}`,
            index === activeIndex ? 'active' : '',
        ]
            .join(' ')
            .trim();
        const id = `${type}-${index}`;
        return { className, id };
    }

    function tabs() {
        return panels?.map((panel: FMTabProps, index: number) => {
            const { title, handleClick } = panel;
            const isActive = index === activeIndex;
            const handleOnClick = (e: any) => {
                handleTabChange(index);
                handleClick && handleClick(e);
            };
            const { className, id } = getClassAndId('tab', index);

            return (
                <button
                    className={className}
                    key={title}
                    role='tab'
                    aria-selected={isActive}
                    id={id}
                    aria-controls={`panel-${index}`}
                    onClick={(e) => handleOnClick(e)}
                    defaultChecked={isActive}
                    onKeyDown={handleKeyPress}
                    ref={(el) => (tabRefs.current[index] = el)}
                >
                    {title}
                </button>
            );
        });
    }

    function tabPanels() {
        return panels?.map((panel: FMTabProps, index: number) => {
            const { panel: tabPanel } = panel;
            const bodyHasMarkup =
                typeof tabPanel === 'string' &&
                tabPanel.trim().startsWith('<') &&
                tabPanel.trim().endsWith('>');
            const { className, id } = getClassAndId('panel', index);

            return (
                <React.Fragment key={id}>
                    {!bodyHasMarkup && (
                        <section
                            className={className}
                            role='tabpanel'
                            id={id}
                            aria-labelledby={`tab-${index}`}
                        >
                            {tabPanel}
                        </section>
                    )}
                    {bodyHasMarkup && (
                        <section
                            className={className}
                            role='tabpanel'
                            id={id}
                            aria-labelledby={`tab-${index}`}
                            dangerouslySetInnerHTML={{
                                __html: tabPanel,
                            }}
                        />
                    )}
                </React.Fragment>
            );
        });
    }

    return (
        <article className='fm-tabs-container'>
            <div className='fm-tabs__list' role='tablist'>
                {tabs()}
            </div>
            <div className='fm-tabs-panels'>{tabPanels()}</div>
        </article>
    );
};
export default FMTabs;
