import AppConfigurationService from './app-configuration-service/app-configuration-service';
import RecallsService from './recalls-service/recalls-service';
import LoyaltyPointsService from './loyalty-points-service/loyalty-points-service';
import NvoService from './nvo-service/nvo-service';
import { SyncMapService } from './sync-service/sync-map-service';
import VehicleTypeService from './vehicle-type-service/vehicle-type-service';
import ConnectedVehicleService from './connected-vehicle-service/connected-vehicle-service';
import MmotaStatusService from './mmota-status-service/mmota-status-service';
import VhaService from './vha-service/vha-service';
import VehicleServiceHistory from './vehicle-service-history/vehicle-service-history';
import WarrantyService from './warranty-service/warranty-service';
import BrightCoveService from './brightcove-service/brightcove-service';
import DealerSearchService from './osb-service/google-map-service/dealer-search-service';
import OsbContentService from './osb-service/osb-content-service/osb-content-service';
import OsbRetrieveAccessCodeService from './osb-service/retrieve-access-code/retrieve-access-code';
import OsbVehicleRecallService from './osb-service/vehicle-recall-service/vehicle-recall-service';
import MaintenanceScheduleService from './maintenance-schedule-service/maintenance-schedule-service';
import VhrReportService from './vhr-service/vhr-report';
import RedeemRebateService from './redeem-rebate-service/redeem-rebate-service';
import ChooseMethodService from './rebate-choose-method-service/choose-method-service';
import CheckStatusService from './check-status-service/check-status-service';
import VehiclePrognosticsService from './vehicle-prognostics-service/vehicle-prognostics-service';
import OsbUserVehicleService from './osb-service/user-vehicle-service/user-vehicle-service';
import FordAccountService from './osb-service/ford-account-service/ford-account-service';
import OsbImageUploadService from './osb-service/image-upload-service/image-upload-service';
import PreferredDealerServiceXPsService from './preferred-dealer-ServiceXP/preferred-dealer-ServiceXP';
import { OSBDealerServicesClient } from './osb-service/services-info-service/dealer-services-client';
import { OSBVehicleLookUpClient } from './osb-service/vehicle-lookup-service/vehicle-lookup-service-client';
import PriceCalculatorContentService from './service-price-calculator/service-price-calculator';
import { OSBDealerCalendarAvailabilityClient } from './osb-service/dealer-calender-service/dealer-calendar-availability-client';
import RetrieveProfileUTE from './retrieve-profile-UTE/retrieve-profile-UTE';
import { OSBServiceBookingClient } from './osb-service/create-booking-service/service-booking-client';
import { OSBRetrieveBookingClient } from './osb-service/retrieve-booking-service/retrieve-booking-client';
import { OSBAmendBookingClient } from './osb-service/amend-booking-service/amend-booking-client';
import { OsbDealerDetailsServiceClient } from './osb-service/dealer-details-dsl-service/dealer-details-dsl-service-client';
import VehicleEngineTypeService from './vehicle-engine-type/vehicle-engine-type';
import ZafEsbService from './zaf-esb-service/zaf-esb-service';
import JiraService from './jira-service/jira-service';
import FordRidesTripsService from './ford-rides-trips-service/ford-rides-trips-service';
import FprUrlValidationService from './fpr-url-validation-service/fpr-url-validation-service';
import HistoricalReleaseNotesService from './historical-release-notes-service/historical-release-notes-service';

const ServiceHandler = {
    AppConfigurationService: new AppConfigurationService(),
    RecallsService: new RecallsService(),
    LoyaltyPointsService: new LoyaltyPointsService(),
    NvoService: new NvoService(),
    SyncMapService: new SyncMapService(),
    VehicleTypeService: new VehicleTypeService(),
    ConnectedVehicleService: new ConnectedVehicleService(),
    MmotaStatusService: new MmotaStatusService(),
    VhaService: new VhaService(),
    VehicleServiceHistory: new VehicleServiceHistory(),
    WarrantyService: new WarrantyService(),
    OsbDslDealerDetailsService: new OsbDealerDetailsServiceClient(),
    OsbImageUploadService: new OsbImageUploadService(),
    BrightCoveService: new BrightCoveService(),
    DealerSearchService: new DealerSearchService(),
    OsbContentService: new OsbContentService(),
    OsbRetrieveAccessCodeService: new OsbRetrieveAccessCodeService(),
    OsbVehicleRecallService: new OsbVehicleRecallService(),
    MaintenanceScheduleService: new MaintenanceScheduleService(),
    VhrReportService: new VhrReportService(),
    RedeemRebateService: new RedeemRebateService(),
    ChooseMethodService: new ChooseMethodService(),
    CheckStatusService: new CheckStatusService(),
    VehiclePrognosticsService: new VehiclePrognosticsService(),
    OsbUserVehicleService: new OsbUserVehicleService(),
    FordAccountService: new FordAccountService(),
    PreferredDealerServiceXPsService: new PreferredDealerServiceXPsService(),
    OsbDealerServices: new OSBDealerServicesClient(),
    OsbVehicleLookUp: new OSBVehicleLookUpClient(),
    PriceCalculatorContentService: new PriceCalculatorContentService(),
    OsbDealerCalendarAvailability: new OSBDealerCalendarAvailabilityClient(),
    RetrieveProfileUTE: new RetrieveProfileUTE(),
    OsbServiceBooking: new OSBServiceBookingClient(),
    OSBRetrieveBooking: new OSBRetrieveBookingClient(),
    OSBAmendBooking: new OSBAmendBookingClient(),
    VehicleEngineTypeService: new VehicleEngineTypeService(),
    ZafEsbService: new ZafEsbService(),
    JiraService: new JiraService(),
    fordRidesTripsService: new FordRidesTripsService(),
    fprUrlValidationService: new FprUrlValidationService(),
    HistoricalReleaseNotesService: new HistoricalReleaseNotesService(),
};

export default ServiceHandler;
