import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { SearchContentProps } from '../hooks/use-search-content';
import AutoComplete from '@own/accessible-autocomplete/react';
import AstuteService from '../../../../support/services/astute-service/astute-service';
import {
    ASTUTE,
    RECALLS,
    SUPPORT_HUB_REGEX,
    TABLET_BREAKPOINT,
} from '../../../../support/support-constants';
import ServerContext from '../../../../contexts/serverContext';
import siteMapService from '../../../../support/services/site-map-service/site-map-service';
import AppConfigurationService from '../../../../services/app-configuration-service/app-configuration-service';
import './pre-imagine-search-bar.scss';
import '../search-bar-input/Autocomplete.scss';
import xss from 'xss';
import { DiscoverabilityPageLoad } from '../../../../services/shortcode-service/shortcode-service';
import { useAnalytics } from '../../../../hooks/use-analytics';
import { ActivityIndicator } from '../../../common/activity-indicator/activity-indicator';
import VehicleTypeService from '../../../../services/vehicle-type-service/vehicle-type-service';
import { BRAND } from '../../../../constants';
import { useRecallsSearchTermsContent } from '../hooks/use-recalls-search-terms-content';
import serverSideService from '../../../../services/server-side-service/server-side-service';
import localStorageWrapper from '../../../utils/local-storage-wrapper/localStorageWrapper';
interface Props {
    hideSearchBarTitle?: boolean;
    searchTerm?: string;
    customId?: string;
    searchContent: SearchContentProps;
}
interface AutocompleteProps {
    id: string;
    source: Function;
    placeholder: string;
    onConfirm: Function;
    experimentalAllowAnyInput: boolean;
    defaultValue: string;
    ariaLabelInput: string;
    ariaLabelUl: string;
}
const PreImagineSearchBar = (props: Props) => {
    const astuteService = new AstuteService();
    const [mobileOrDesktop, setMobileOrDesktop] = useState('');
    const [fireEvents] = useAnalytics();
    const searchBarId = props.customId || 'search-bar-';
    const serverContext = useContext(ServerContext);
    const {
        currentLanguageRegionCode,
        current3LetterCountryCode,
    } = serverContext;
    const recallsSearchTermsContent = useRecallsSearchTermsContent();
    const isLincoln =
        new AppConfigurationService().brand.toLowerCase() === 'lincoln';
    const cancelSearchIconFord = './icons/ford-icons/cancel-search-x.svg';
    const searchIconFord = './icons/ford-icons/magnifying-glass-preimagine.svg';
    const searchIconLincoln = './icons/lincoln-icons/search.svg';
    const searchIcon = isLincoln ? searchIconLincoln : searchIconFord;
    const [isSearchRunning, setIsSearchRunning] = useState(false);
    const hideSearchBar = ASTUTE.DOMAINS_TO_SKIP_BBT_SEARCH.includes(
        serverContext.domain
    );
    const redirectToAstuteSearch = (searchTerm: string) => {
        if (searchTerm) {
            setIsSearchRunning(true);
            const searchBox = document.getElementById(
                `${searchBarId}${mobileOrDesktop}`
            ) as HTMLInputElement;
            const sanitizedSearchTerm = searchTerm.replace(
                SUPPORT_HUB_REGEX.INVALID_SEARCH_CHARS,
                ''
            );
            searchBox.value = sanitizedSearchTerm;
            const slugifiedSearchTerm = xss(sanitizedSearchTerm, {
                whiteList: {}, // empty, means filter out all tags
                stripIgnoreTag: true, // filter out all HTML not in the whitelist
                stripIgnoreTagBody: ['script'], // the script tag is a special case, we need
                // to filter out its content
            }).replace(SUPPORT_HUB_REGEX.SPACES, '-');
            if (ASTUTE.SUPPORTED_MARKETS.includes(currentLanguageRegionCode)) {
                window.location.href = serverContext.root
                    .substring(0, serverContext.root.length - 1)
                    .concat(
                        siteMapService.getPathFromRouterConfigs(
                            currentLanguageRegionCode,
                            serverContext.brand,
                            'AstuteSearch',
                            [slugifiedSearchTerm]
                        )
                    );
            } else {
                const target = props.searchContent.ctaTargetBlank
                    ? '_blank'
                    : '_self';
                window.open(
                    props.searchContent?.searchLink + searchTerm,
                    target
                );
            }
        }
    };

    const checkInputIsVin = async (searchTerm: string) => {
        if (searchTerm) {
            if (
                new RegExp(SUPPORT_HUB_REGEX.VALID_VIN_REGEX).test(searchTerm)
            ) {
                setIsSearchRunning(true);
                if (
                    await new VehicleTypeService()
                        .request(
                            searchTerm,
                            currentLanguageRegionCode,
                            current3LetterCountryCode?.toUpperCase()
                        )
                        .then(() => true)
                        .catch(() => false)
                ) {
                    const discoverabilityPageLoad: DiscoverabilityPageLoad = {
                        searchKeyword: searchTerm,
                    };
                    fireEvents(
                        ['owner-article'],
                        undefined,
                        { discoverabilityPageLoad },
                        false
                    );
                    window.location.href = serverContext.root
                        .substring(0, serverContext.root.length - 1)
                        .concat(
                            siteMapService.getPathFromRouterConfigs(
                                currentLanguageRegionCode,
                                serverContext.brand,
                                'VehicleVinView',
                                [searchTerm]
                            )
                        );
                } else redirectToAstuteSearch(searchTerm);
            } else redirectToAstuteSearch(searchTerm);
        }
    };
    const checkInputIsCampaignId = async (searchTerm: string) => {
        if (searchTerm) {
            if (
                new RegExp(SUPPORT_HUB_REGEX.VALID_CAMPAIGN_ID_REGEX).test(
                    searchTerm
                )
            ) {
                setIsSearchRunning(true);
                let recallsRedirect = 'RecallsPage';
                let param = '';
                if (
                    RECALLS.CAMPAIGN_SEARCH_AVAILABLE.includes(
                        serverContext.currentLanguageRegionCode
                    )
                ) {
                    recallsRedirect = 'RecallsCampaignDetailsPage';
                    param = searchTerm;
                }
                window.location.href = serverContext.root
                    .substring(0, serverContext.root.length - 1)
                    .concat(
                        siteMapService.getPathFromRouterConfigs(
                            currentLanguageRegionCode,
                            serverContext.brand,
                            recallsRedirect,
                            [param]
                        )
                    );
            }
        }
    };
    const checkInputIsRecallsSearchTerm = async (searchTerm: string) => {
        if (searchTerm && recallsSearchTermsContent?.termList) {
            if (
                recallsSearchTermsContent.termList.some(term =>
                    searchTerm.toLowerCase().includes(term.toLowerCase())
                )
            ) {
                setIsSearchRunning(true);
                let recallsRedirect = 'RecallsPage';
                if (
                    RECALLS.TAKATA_RECALLS_AVAILABLE.includes(
                        currentLanguageRegionCode
                    ) &&
                    serverContext.brand === BRAND.ford.LONG_NAME
                ) {
                    recallsRedirect = 'TakataRecallsPage';
                }
                window.location.href = serverContext.root
                    .substring(0, serverContext.root.length - 1)
                    .concat(
                        siteMapService.getPathFromRouterConfigs(
                            currentLanguageRegionCode,
                            serverContext.brand,
                            recallsRedirect,
                            []
                        )
                    );
            }
        }
    };
    const search = (event: FormEvent) => {
        event.preventDefault();
        const currentTarget = event.currentTarget;
        const inputValue =
            currentTarget &&
            currentTarget.getElementsByTagName('input').item(0)?.value;
        checkInputIsVin(inputValue || '');
        checkInputIsCampaignId(inputValue || '');
        checkInputIsRecallsSearchTerm(inputValue || '');
    };

    useEffect(() => {
        if (!props.searchContent.hideInput) {
            window.innerWidth <= TABLET_BREAKPOINT
                ? setMobileOrDesktop('mobile')
                : setMobileOrDesktop('desktop');
        }
    }, [props.searchContent]);

    const suggest = async (
        currentSearchTerm: string,
        syncResults: Function
    ) => {
        const sanitizedSearchTerm = currentSearchTerm.replace(
            SUPPORT_HUB_REGEX.INVALID_SEARCH_CHARS,
            ''
        );
        const elasticSuggestions = await astuteService.getSuggestedResults(
            serverContext.currentLanguageRegionCode,
            sanitizedSearchTerm,
            localStorageWrapper.getItem(
                serverContext.currentLanguageRegionCode + 'sessionID'
            ) ?? '',
            serverContext.brand
        );
        localStorageWrapper.setItem(
            serverContext.currentLanguageRegionCode + 'sessionID',
            elasticSuggestions?.sessionID
        );
        syncResults(elasticSuggestions?.suggestions);
    };

    const autocompleteProps: AutocompleteProps = {
        id: `${searchBarId}${mobileOrDesktop}`,
        source: suggest,
        defaultValue: props.searchTerm || '',
        placeholder: '',
        onConfirm:
            checkInputIsVin ||
            checkInputIsCampaignId ||
            checkInputIsRecallsSearchTerm,
        experimentalAllowAnyInput: true,
        ariaLabelInput: props.searchContent.searchBarAriaLabel || '',
        ariaLabelUl: props.searchContent.ulAriaLabel,
    };
    const clearText = () => {
        if (serverSideService.isClientSide()) {
            const inputs = document.getElementsByClassName(
                'autocomplete__input'
            );
            if (inputs.length > 0) {
                Array.from(inputs).forEach(input => {
                    (input as HTMLInputElement).value = '';
                });
            }
        }
    };
    return (
        <>
            {props.searchContent && !hideSearchBar && (
                <div className="preimagine-search-bar">
                    <div
                        className={
                            'search-container ' +
                            (props.searchContent.hideInput
                                ? 'search-container-hide-input'
                                : '')
                        }
                    >
                        {isSearchRunning ? (
                            <div
                                data-testid="search-spinner"
                                className="search-spinner-container"
                                id="search-spinner"
                            >
                                <ActivityIndicator />
                            </div>
                        ) : null}
                        {!props.hideSearchBarTitle &&
                            props.searchContent.searchTitle && (
                                <h2 className="search-title">
                                    {props.searchContent.searchTitle}
                                </h2>
                            )}
                        {!props.hideSearchBarTitle &&
                            props.searchContent.searchBarHelperText && (
                                <div className="search-helper-text">
                                    {props.searchContent.searchBarHelperText}
                                </div>
                            )}
                        {!props.searchContent.hideInput && (
                            <form
                                onSubmit={search}
                                data-testid="search-form"
                                role="search"
                                action="."
                                className="search-form"
                            >
                                <button
                                    aria-label={
                                        props.searchContent
                                            .searchButtonAriaLabel
                                    }
                                    className="search-icons"
                                >
                                    <img src={searchIcon} alt="Search Icon" />
                                </button>

                                <AutoComplete
                                    {...autocompleteProps}
                                ></AutoComplete>
                                <button
                                    type="button"
                                    aria-label={
                                        props.searchContent
                                            .searchButtonAriaLabel
                                    }
                                    className="search-icons"
                                    onClick={clearText}
                                >
                                    <img
                                        src={cancelSearchIconFord}
                                        alt="cancel search icon"
                                    />
                                </button>
                            </form>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default PreImagineSearchBar;
